import React, { useState, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";
import Navbar from "../Navbar";
// import "../Navbar.css";
import TradingViewWidget from './TradingView';
import Footer from '../Footer';
import user from '../../assets/logo/user.png'
import email from '../../assets/logo/email.png'
import hp from '../../assets/logo/smartphone.png'
import massage from '../../assets/logo/speech-bubble.png'
import '../About/Tenkapage.css'
import link from "../../assets/logo/link.png"
import clipboardCopy from 'clipboard-copy';
import updown from  "../../assets/logo/updown.png"
import pdf from "../../assets/logo/pdf-file.png"
import { useLocation } from "react-router-dom";
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { BASE_API_URL } from '../../config/constants';

const TenkaPage = () => {
  const RECAPTCHA_SITE_KEY = '6LegwGEqAAAAAFIQ1dvwjEKwYlg37-kHouHcS3cK';
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSideMenuHidden, setIsSideMenuHidden] = useState(false);
  const [notification, setNotification] = useState('');
  const [postData, setPostData] = useState([]);
  const [postDta, setPostDta] = useState([]);
  const [postDta1, setPostDta1] = useState([]);
  const [postDta2, setPostDta2] = useState([]);
  const [postDta3, setPostDta3] = useState([]);
  const [postDta4, setPostDta4] = useState([]);
  const [postDta5, setPostDta5] = useState([]);
  const [postDta6, setPostDta6] = useState([]);
  const [postDta7, setPostDta7] = useState([]);
  const [postDta8, setPostDta8] = useState([]);
  const [postDta9, setPostDta9] = useState([]);
  const [postDta10, setPostDta10] = useState([]);
  const [postDta11, setPostDta11] = useState([]);

  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    setIsSideMenuHidden(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsSideMenuHidden(!isMobileMenuOpen); // Tambahkan perubahan ini
  };
  
  
  
  const handleCopy = (linkurl) => {
    clipboardCopy(linkurl);
    setNotification('Copy CLipboard')   

    setTimeout(() => {
      setNotification('');
    }, 3000);
  };
  
  const location = useLocation();
  useEffect(() => {
    const fetchPostData = async () => {
      try {
           if(location.pathname === "/hubungan-investor"){
            const apiUrl11 = BASE_API_URL+'/posts/list/category?group=LKT%202024&page=1&limit=13&order=desc';
          const response11 = await axios.get(apiUrl11);//2024
          setPostDta11(response11.data.posts);
          const apiUrl = BASE_API_URL+'/posts/list/category?group=LKT%202023&page=1&limit=13&order=desc';
          const response = await axios.get(apiUrl);//2023
          setPostData(response.data.posts);
          const apiUrl1 = BASE_API_URL+'/posts/list/category?group=LKT%202022&page=1&limit=13&order=desc';
          const response1 = await axios.get(apiUrl1);//2022
          setPostDta1(response1.data.posts);
          const apiUrl2 = BASE_API_URL+'/posts/list/category?group=LKT%202021&page=1&limit=13&order=desc';
          const response2 = await axios.get(apiUrl2);//2021
          setPostDta2(response2.data.posts);
          const apiUrl3 = BASE_API_URL+'/posts/list/category?group=LKT%202020&page=1&limit=13&order=desc';
          const response3 = await axios.get(apiUrl3);//2020
          setPostDta3(response3.data.posts);
          const apiUrl4 = BASE_API_URL+'/posts/list/category?group=LKT%202019&page=1&limit=13&order=desc';
          const response4 = await axios.get(apiUrl4);//2019
          setPostDta4(response4.data.posts);
          const apiUrl5 = BASE_API_URL+'/posts/list/category?group=LKT%202018&page=1&limit=13&order=desc';
          const response5 = await axios.get(apiUrl5);//2018
          setPostDta5(response5.data.posts);
          const apiUrl6 = BASE_API_URL+'/posts/list/category?group=LKT%202017&page=1&limit=13&order=desc';
          const response6 = await axios.get(apiUrl6);//2017
          setPostDta6(response6.data.posts);
          const apiUrl7 = BASE_API_URL+'/posts/list/category?group=LKT%202016&page=1&limit=13&order=desc';
          const respons7 = await axios.get(apiUrl7);//2016
          setPostDta7(respons7.data.posts);
          const apiUrl8 = BASE_API_URL+'/posts/list/category?group=LKT%202015&page=1&limit=13&order=desc';
          const respons8 = await axios.get(apiUrl8);//2015
          setPostDta8(respons8.data.posts);
          const apiUrl9 = BASE_API_URL+'/posts/list/category?group=LKT%202014&page=1&limit=13&order=desc';
          const response9 = await axios.get(apiUrl9);//2014
          setPostDta9(response9.data.posts);
          const apiUrl10 = BASE_API_URL+'/posts/list/category?group=LKT%202013&page=1&limit=13&order=desc';
          const response10 = await axios.get(apiUrl10);//2013
          setPostDta10(response10.data.posts);
          return
        }
        else if(location.pathname === "/hubungan-investor/laporan-tahunan"){
          const apiUrl = BASE_API_URL+'/posts/list/category?group=Laporan%20Tahunan&page=1&limit=15&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          return
        }
        else if(location.pathname === "/hubungan-investor/rups"){
          const apiUrl = BASE_API_URL+'/posts/list/menu_category?category=*6B462CB4DD641021AA46A360968BB179E0A6947F&page=1&limit=15&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          return
        }
        else if(location.pathname === "/hubungan-investor/rups/2"){
          const apiUrl = BASE_API_URL+'/posts/list/menu_category?category=*6C16C138F602AA6B470F37F7A3EC2BF2AF8C583D&page=1&limit=16&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          const apiUrl1 = BASE_API_URL+'/posts/list/menu_category?category=*6C16C138F602AA6B470F37F7A3EC2BF2AF8C583D&page=1&limit=15&order=desc';
          const response1 = await axios.get(apiUrl1);
          setPostData(response1.data.posts);
          return
        }
        else if(location.pathname === "/hubungan-investor/rups/3"){
          const apiUrl = BASE_API_URL+'/posts/list/menu_category?category=*6E9CB955709FD04CC23AA75D013C4411E9C51781&page=1&limit=16&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          return
        }
        else if(location.pathname === "/hubungan-investor/saham"){
          const apiUrl = BASE_API_URL+'/posts/list/menu_category?category=*1FB259F059F8D6E3E3F80616C04F3CA55F71FA51&page=1&limit=10&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          return
        }
        else if(location.pathname === "/hubungan-investor/hubungi-kami"){
          const apiUrl = BASE_API_URL+'/posts/list/menu_category?category=*1FB259F059F8D6E3E3F80616C04F3CA55F71FA51&page=1&limit=10&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          return
        }
      }  
      catch (error) {
        console.error('Error fetching data:', error);
        setPostData({});
        // setIsLoading(false); // Set isLoading ke false jika terjadi error
      }
    };
    fetchPostData();
  }, [location.pathname]);
  const postArray = Array.isArray(postData) ? postData : [];
  const postArray1 = Array.isArray(postDta) ? postDta : [];
  const postArray5 = Array.isArray(postDta4) ? postDta4 : [];
  const postArray10 = Array.isArray(postDta9) ? postDta9 : [];


  


  const SideMenu = () => {
    const location = useLocation();
    
     if(location.pathname === "/hubungan-investor"){
      return (
        <>
          <p><NavLink to='/hubungan-investor' className="side_active">{selectedLanguage === 'en' ? 'Financial Statement ' : 'Laporan Keuangan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/laporan-tahunan'>{selectedLanguage === 'en' ? 'Annual Reports' : 'Laporan Tahunan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/rups'>{selectedLanguage === 'en' ? 'RUPS' : 'RUPS'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/saham'>{selectedLanguage === 'en' ? 'Stock Information' : 'Informasi Saham'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/hubungi-kami'>{selectedLanguage === 'en' ? 'Contact Us' : 'Hubungi Kami'}</NavLink></p>
        </>
      );
    } else if(location.pathname === "/hubungan-investor/laporan-tahunan"){
      return (
        <>
          <p><NavLink to='/hubungan-investor'>{selectedLanguage === 'en' ? 'Financial Statement ' : 'Laporan Keuangan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/laporan-tahunan' className="side_active">{selectedLanguage === 'en' ? 'Annual Reports' : 'Laporan Tahunan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/rups'>{selectedLanguage === 'en' ? 'RUPS' : 'RUPS'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/saham'>{selectedLanguage === 'en' ? 'Stock Information' : 'Informasi Saham'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/hubungi-kami'>{selectedLanguage === 'en' ? 'Contact Us' : 'Hubungi Kami'}</NavLink></p>
        </>
      );
    } else if(location.pathname === "/hubungan-investor/rups"
    || (location.pathname === "/hubungan-investor/rups/2")
    || (location.pathname === "/hubungan-investor/rups/3")){
      return (
        <>
          <p><NavLink to='/hubungan-investor'>{selectedLanguage === 'en' ? 'Financial Statement ' : 'Laporan Keuangan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/laporan-tahunan'>{selectedLanguage === 'en' ? 'Annual Reports' : 'Laporan Tahunan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/rups' className="side_active">{selectedLanguage === 'en' ? 'RUPS' : 'RUPS'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/saham'>{selectedLanguage === 'en' ? 'Stock Information' : 'Informasi Saham'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/hubungi-kami'>{selectedLanguage === 'en' ? 'Contact Us' : 'Hubungi Kami'}</NavLink></p>
        </>
      );
    } else if(location.pathname === "/hubungan-investor/saham"){
      return (
        <>
          <p><NavLink to='/hubungan-investor'>{selectedLanguage === 'en' ? 'Financial Statement ' : 'Laporan Keuangan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/laporan-tahunan'>{selectedLanguage === 'en' ? 'Annual Reports' : 'Laporan Tahunan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/rups'>{selectedLanguage === 'en' ? 'RUPS' : 'RUPS'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/saham' className="side_active">{selectedLanguage === 'en' ? 'Stock Information' : 'Informasi Saham'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/hubungi-kami'>{selectedLanguage === 'en' ? 'Contact Us' : 'Hubungi Kami'}</NavLink></p>
        </>
      );
    } else if(location.pathname === "/hubungan-investor/hubungi-kami"){
      return (
        <>
          <p><NavLink to='/hubungan-investor'>{selectedLanguage === 'en' ? 'Financial Statement ' : 'Laporan Keuangan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/laporan-tahunan'>{selectedLanguage === 'en' ? 'Annual Reports' : 'Laporan Tahunan'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/rups'>{selectedLanguage === 'en' ? 'RUPS' : 'RUPS'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/saham'>{selectedLanguage === 'en' ? 'Stock Information' : 'Informasi Saham'}</NavLink></p>
          <p><NavLink to='/hubungan-investor/hubungi-kami' className="side_active">{selectedLanguage === 'en' ? 'Contact Us' : 'Hubungi Kami'}</NavLink></p>
        </>
      );
    }
    
  }
  
  const Customnhp = () => {

    
    const location = useLocation();
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const [formData, setFormData] = useState({
      nama: '',
      email: '',
      no_hp: '',
      pesan: '',
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!recaptchaValue) {
        alert('Please complete the reCAPTCHA.');
        return;
      }
  
      try {
        const payload = {
          ...formData,
          recaptcha: recaptchaValue
        };
  
        const response = await fetch(BASE_API_URL + '/proses_form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
  
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
  
        const result = await response.json();
        console.log(result);
  
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    };
  

    const handleDownload = (fileUrl) => {
      window.open(fileUrl, '_blank');
    };

    const handleRecaptchaChange = (value) => {
      setRecaptchaValue(value);
    };

    if (location.pathname === "/hubungan-investor") {
      const postArray2024 = Array.isArray(postDta11) ? postDta11 : [];
      const postArray2023 = Array.isArray(postData) ? postData : [];
      const postArray2022 = Array.isArray(postDta1) ? postDta1 : [];
      const postArray2021 = Array.isArray(postDta2) ? postDta2 : [];
      const postArray2020 = Array.isArray(postDta3) ? postDta3 : [];
      const postArray2019 = Array.isArray(postDta4) ? postDta4 : [];
      const postArray2018 = Array.isArray(postDta5) ? postDta5 : [];
      const postArray2017 = Array.isArray(postDta6) ? postDta6 : [];
      const postArray2016 = Array.isArray(postDta7) ? postDta7 : [];
      const postArray2015 = Array.isArray(postDta8) ? postDta8 : [];
      const postArray2014 = Array.isArray(postDta9) ? postDta9 : [];
      const postArray2013 = Array.isArray(postDta10) ? postDta10 : [];

      return (
        <>
        <h3 className="tt">2024</h3>
        {postArray2024.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
        <h3 className="tt">2023</h3>
        {postArray2023.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
            <h3 className="tt">2022</h3>
            {postArray2022.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
            <h3 className="tt">2021</h3>
            {postArray2021.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
            <hr/>
             <h3 className="tt">2020</h3>
        {postArray2020.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2019</h3>
        {postArray2019.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2018</h3>
        {postArray2018.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2017</h3>
        {postArray2017.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2016</h3>
        {postArray2016.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2015</h3>
        {postArray2015.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2014</h3>
        {postArray2014.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2013</h3>
        {postArray2013.map((post, index) => (
              <div key={index}>
                <div className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              </div>
            ))}
             <hr/>
        </>
      );
    } else if (location.pathname === "/hubungan-investor/laporan-tahunan") {
      return (
        <>
            <center>
            <h3>{selectedLanguage === 'id' ?  'Laporan Tahunan' : 'Annual Report'}</h3>
            </center>
            <br/>

              <div className='img_dewan'>
              {postArray.map((post, index) => (
              <div key={index}>
              <img src={post.Img_path} onClick={() => handleDownload(post.File_upload)} style={{cursor: "pointer"}} className='content_SO' />
                          <h4>{post.Title}</h4>
                          <p dangerouslySetInnerHTML={{ __html: post.Content_tx }}></p>
                </div>
            ))}
                </div>
        </>
      );
      
    } else if (location.pathname === "/hubungan-investor/rups") {
      return (
        <>
          <h2 href='/hubungan-investor/rups' >{selectedLanguage === 'id' ?  'Pengumuman': 'Announcement'}
          </h2>
              <div>
              <div>
                  {postArray.map((post,  index) => (
                    <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                        </div>
                        <div>
                          <img src={updown} className='up' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
            </div>
            <br/>
            <br/>
          <h2 href='/hubungan-investor/rups/2'>{selectedLanguage === 'id' ?  'Panggilan': 'Invitation'}</h2>
            <div>
              <div>
              {postArray.map((post, index) => (
                <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                        </div>
                        <div>
                          <img src={updown} className='up' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
            </div>
            <br/>
            <br/>
         <h2>{selectedLanguage === 'id' ?  'Keputusan / Notulent Rapat': 'Decision / Minute of Meeting'}</h2>
                <div className='content_ rups'>
            <div>
              <div>
              {postArray.map((post, index) => (
                <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                        </div>
                        <div>
                          <img src={updown} className='up' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      );
      }

      else if (location.pathname === "/hubungan-investor/saham") {
        return (
          <>
           <div>
            {postArray.map((post, index) => (
                <div key={index}>
                    <div className='content_lp'>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)} dangerouslySetInnerHTML={{ __html: post.Content_tx }}></p>
                        </div>
                      </div>
                      </div>
                  ))}
           </div>
          </>
        );
        } else if (location.pathname === "/hubungan-investor/hubungi-kami") {
          return (
            <>
            <div className='contact_us'>
            <div className="form">
              <div className="col">
                <form onSubmit={handleSubmit}>
                  <div class="mb-3">
                    <label for="Name" class="form-label style-headingkotak">
                      Nama Lengkap <span style={{color: "red"}}>*</span>
                    </label>
                    <span></span>
                    <input
                      type="text"
                      name='nama'
                      placeholder= 'Nama Lengkap'
                      class="form-control size-textfieldCon"
                      id="name"
                      onChange={handleChange}
                      value={formData.nama}
                      required
                      style={{
                        paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                        backgroundImage: `url(${user})`,
                        backgroundSize: '16px', // Sesuaikan ukuran gambar
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        opacity: '80%' // Sesuaikan posisi gambar
                      }}
                    />
                  </div>
  
                  <div class="text_field">
                    <label for="Email" class="form-label style-headingkotak">
                      Email <span style={{color: "red"}}>*</span>
                    </label>
                    <input
                      type="email"
                      name='email'
                      placeholder='Email'
                      class="form-control size-textfieldCon"
                      id="email"
                      onChange={handleChange}
                      value={formData.email}
                      required
                      style={{
                        paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                        backgroundImage: `url(${email})`,
                        backgroundSize: '16px', // Sesuaikan ukuran gambar
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        opacity: '80%' // Sesuaikan posisi gambar
                      }}
                    />
                  </div>
  
                  <div class="text_field">
                    <label
                      for="PhoneNumber"
                      class="form-label style-headingkotak"
                    >
                      No Handphone <span style={{color: "red"}}>*</span>
                    </label>
                    <input
                      type="text"
                      name='no_hp'
                      placeholder='No Hanphone'
                      class="form-control size-textfieldCon"
                      id="phone"
                      value={formData.no_hp}
                      onChange={handleChange}
                      required
                      style={{
                        paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                        backgroundImage: `url(${hp})`,
                        backgroundSize: '16px', // Sesuaikan ukuran gambar
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        opacity: '80%' // Sesuaikan posisi gambar
                      }}
                    />
                  </div>
  
                  
                  <div className="text_field">
                    <label class="form-control-label style-headingkotak">
                      Pesan <span style={{color: "red"}}>*</span>
                    </label>
                    <textarea
                      rows="5"
                      class="form-control size-textfieldCon"
                      name="pesan"
                      placeholder='Pesan'
                      minlength="10"
                      onChange={handleChange}
                      value={formData.pesan}
                      required
                      style={{
                        paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                        backgroundImage: `url(${massage})`,
                        backgroundSize: '16px', // Sesuaikan ukuran gambar
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px 8px ',
                        marginBottom: '10px',
                        opacity: '80%' // Sesuaikan posisi gambar
                      }}
                    ></textarea>
                  </div>
  
                  <div className="mt-3">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={handleRecaptchaChange}
                    />
                  </div>
  
                  <button type="submit" class="btn-submit ">
                    Submit
                  </button>
                  
                </form>
              </div>

              <hr/>
  
              <div className='col'>
              <p className="heading-kotakkanan"> {selectedLanguage === 'id' ?  'Hubungi Kami': 'Contact Us'} </p>
                <p className="isi-kotakkanan">
                  PT.Electronic City Indonesia, Tbk. <br/>
                  Jl. Jend. Sudirman Kav. 52-53, SCBD Lot. 22 Jakarta Selatan 12190
                </p>
              
                <div className="grid-kotakkanan">
                    <p className="heading-kotakkanan ">Email</p>

                    <a href={`mailto:corporatesecretary@electronic-city.co.id`}><p className='e_corp' >corporatesecretary@electronic-city.co.id</p></a>
  
                    <p className="heading-kotakkanan ">Phone</p>
  
                    <p className="isi-kotakkanan">021 - 5151177</p>
                </div>
                
              </div>
            </div>
            </div>
            </>
          );
          }
  

      else {
      return (
        <h1>Halaman Kosong</h1>
      );
    }
  }

  const CustomKonten = () => {
    const location = useLocation();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

      const [formData, setFormData] = useState({
        nama: '',
        email: '',
        no_hp: '',
        pesan: '',
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!recaptchaValue) {
          alert('Please complete the reCAPTCHA.');
          return;
        }
    
        try {
          const payload = {
            ...formData,
            recaptcha: recaptchaValue
          };
    
          const response = await fetch(BASE_API_URL + '/proses_form', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
    
          if (!response.ok) {
            throw new Error('Failed to submit form');
          }
    
          const result = await response.json();
          console.log(result);
    
          window.location.reload();
        } catch (error) {
          console.error(error);
        }
      };
    

    const handleDownload = (fileUrl) => {
      window.open(fileUrl, '_blank');
    };
     if (location.pathname === "/hubungan-investor") {
      const postArray2024 = Array.isArray(postDta11) ? postDta11 : [];
      const postArray2023 = Array.isArray(postData) ? postData : [];
      const postArray2022 = Array.isArray(postDta1) ? postDta1 : [];
      const postArray2021 = Array.isArray(postDta2) ? postDta2 : [];
      const postArray2020 = Array.isArray(postDta3) ? postDta3 : [];
      const postArray2019 = Array.isArray(postDta4) ? postDta4 : [];
      const postArray2018 = Array.isArray(postDta5) ? postDta5 : [];
      const postArray2017 = Array.isArray(postDta6) ? postDta6 : [];
      const postArray2016 = Array.isArray(postDta7) ? postDta7 : [];
      const postArray2015 = Array.isArray(postDta8) ? postDta8 : [];
      const postArray2014 = Array.isArray(postDta9) ? postDta9 : [];
      const postArray2013 = Array.isArray(postDta10) ? postDta10 : [];
      return (
        <>
        <h3 className="tt">2024</h3>
        {postArray2024.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
         <h3 className="tt">2023</h3>
        {postArray2023.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
            <h3 className="tt">2022</h3>
            {postArray2022.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
            <h3 className="tt">2021</h3>
            {postArray2021.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
            <hr/>
             <h3 className="tt">2020</h3>
        {postArray2020.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2019</h3>
        {postArray2019.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2018</h3>
        {postArray2018.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2017</h3>
        {postArray2017.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2016</h3>
        {postArray2016.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2015</h3>
        {postArray2015.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2014</h3>
        {postArray2014.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
             <hr/>
             <h3 className="tt">2013</h3>
        {postArray2013.map((post, index) => (
              <div key={index}>
                <div style={{cursor: "pointer"}} className='content_lk'>
               <div className='isi_lk'>
                  <p>{post.Title}</p>
               </div>
             <div>
                  <img src={updown} className='updown' onClick={() => handleDownload(post.File_upload)}></img>
             </div>
             </div>
              <br />
              </div>
            ))}
        </>
      );
    } else if (location.pathname === "/hubungan-investor/laporan-tahunan") {
      return (
        <>
          <h3>{selectedLanguage === 'id' ?  'Laporan Tahunan' : 'Annual Report'}</h3>
            <br/>

              <div className='img_dewan'>
              {postArray.map((post, index) => (
              <div key={index}>
              <img src={post.Img_path} onClick={() => handleDownload(post.File_upload)} style={{cursor: "pointer"}} className='content_SO' />
                          <h4>{post.Title}</h4>
                          <p dangerouslySetInnerHTML={{ __html: post.Content_tx }}></p>
                </div>
            ))}
                </div>
        </>
      );
      
    } else if (location.pathname === "/hubungan-investor/rups") {

       
      return (
        <>
          <div className='content_rups'>
            <div className='tt_rups'>
              <div>
                <NavLink to='/hubungan-investor/rups'>
                  {selectedLanguage === 'id' ?  'Pengumuman': 'Announcement'}
                </NavLink>
              </div>
              <div>
                <NavLink to='/hubungan-investor/rups/2'>
                  {selectedLanguage === 'id' ?  'Panggilan': 'Invitation'}
                </NavLink>
              </div>
              <div>
                <NavLink to='/hubungan-investor/rups/3'>
                  {selectedLanguage === 'id' ?  'Keputusan / Notulent Rapat': 'Decision / Minute of Meeting'}
                </NavLink>
              </div>
            </div>
           <div>
              <div>
                  {postArray.map((post, index) => (
                    <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                        </div>
                        <div>
                          <img src={updown} className='up' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      );
      }

      else if(location.pathname === '/hubungan-investor/rups/2'){
        return(
          <>
          <div className='content_ rups'>
            <div className='tt_rups'>
              <div>
                <NavLink to='/hubungan-investor/rups'>
                  {selectedLanguage === 'id' ?  'Pengumuman': 'Announcement'}
                </NavLink>
              </div>
              <div>
                <NavLink to='/hubungan-investor/rups/2'> 
                  {selectedLanguage === 'id' ?  'Panggilan': 'Invitation'}
                </NavLink>
              </div>
              <div>
                <NavLink to='/hubungan-investor/rups/3'>
                  {selectedLanguage === 'id' ?  'Keputusan / Notulent Rapat': 'Decision / Minute of Meeting'}
                </NavLink>
              </div>
            </div>
            <div>
              <div>
              {postArray.map((post, index) => (
                <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                        </div>
                        <div>
                          <img src={updown} className='up' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
            </div>
          </div>
        </>
        );
      }

      else if(location.pathname === '/hubungan-investor/rups/3'){
        return(
          <>
          <div className='content_ rups'>
            <div className='tt_rups'>
              <div>
                <NavLink to='/hubungan-investor/rups'>
                  {selectedLanguage === 'id' ?  'Pengumuman': 'Announcement'}
                </NavLink>
              </div>
              <div>
                <NavLink to='/hubungan-investor/rups/2'> 
                  {selectedLanguage === 'id' ?  'Panggilan': 'Invitation'}
                </NavLink>
              </div>
              <div>
                <NavLink to='/hubungan-investor/rups/3'>
                  {selectedLanguage === 'id' ?  'Keputusan / Notulent Rapat': 'Decision / Minute of Meeting'}
                </NavLink>
              </div>
            </div>
            <div>
              <div>
              {postArray.map((post, index) => (
                <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                        <div className='isi_lp'>
                          <p onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                        </div>
                        <div>
                          <img src={updown} className='up' onClick={() => handleDownload(post.File_upload)}></img>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
            </div>
          </div>
        </>
        );
      }

      else if (location.pathname === "/hubungan-investor/saham") {
        return (
          <>
            <div style={{paddingTop: "20px !important"}}>
              <div> 
              </div>
              <div className='isi_lp' style={{height: "400px"}}>
              <center><h3 style={{paddingBottom: "25px"}}>{selectedLanguage === 'id' ?  'Informasi Saham' : 'Stock Information'}</h3></center>
                <TradingViewWidget />
              </div>
            </div>
            {/* <div>
            {postArray.map((post, index) => (
                <div key={index}>
                    <div className='content_lp'>
                        <div> 
                        </div>
                        <div className='isi_lp'>
                        <center><h3>{post.Title}</h3></center>
                          <p dangerouslySetInnerHTML={{ __html: post.Content_tx }}></p>
                        </div>
                      </div>
                      </div>
                  ))}
           </div> */}
          </>
        );
        }  else if (location.pathname === "/hubungan-investor/hubungi-kami") {
        return (
          <>
          <div className='contact_us'>
          <div className="form">
            <div className="col field_form">

            {isFormSubmitted && (
              <div className="success-message">
                Pesan Anda berhasil dikirimkan!
              </div>
            )}

              <form onSubmit={handleSubmit}>
                <div class="mb-3">
                  <label for="Name" class="form-label style-headingkotak">
                  {selectedLanguage === 'id' ?  'Nama Lengkap': 'Full Name'} <span style={{color: "red"}}>*</span>
                  </label>
                  <span></span>
                  <input
                    type="text"
                    name='nama'
                    placeholder= {selectedLanguage === 'id' ?  'Nama Lengkap': 'Full Name'} 
                    class="form-control size-textfieldCon"
                    id="name"
                    onChange={handleChange}
                    value={formData.nama}
                    required
                    style={{
                      paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                      backgroundImage: `url(${user})`,
                      backgroundSize: '16px', // Sesuaikan ukuran gambar
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '10px center',
                      opacity: '80%' // Sesuaikan posisi gambar
                    }}
                  />
                </div>

                <div class="text_field">
                  <label for="Email" class="form-label style-headingkotak">
                    Email <span style={{color: "red"}}>*</span>
                  </label>
                  <input
                    type="email"
                    name='email'
                    placeholder='Email'
                    class="form-control size-textfieldCon"
                    id="email"
                    onChange={handleChange}
                    value={formData.email}
                    required
                    style={{
                      paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                      backgroundImage: `url(${email})`,
                      backgroundSize: '16px', // Sesuaikan ukuran gambar
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '10px center',
                      opacity: '80%' // Sesuaikan posisi gambar
                    }}
                  />
                </div>

                <div class="text_field">
                  <label
                    for="PhoneNumber"
                    class="form-label style-headingkotak"
                  >
                    {selectedLanguage === 'id' ?  'No Handphone': 'Phone Number'}  <span style={{color: "red"}}>*</span>
                  </label>
                  <input
                    type="text"
                    name='no_hp'
                    placeholder= {selectedLanguage === 'id' ?  'No Handphone': 'Phone Number'}
                    class="form-control size-textfieldCon"
                    id="phone"
                    value={formData.no_hp}
                    onChange={handleChange}
                    required
                    style={{
                      paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                      backgroundImage: `url(${hp})`,
                      backgroundSize: '16px', // Sesuaikan ukuran gambar
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '10px center',
                      opacity: '80%' // Sesuaikan posisi gambar
                    }}
                  />
                </div>

                
                <div className="text_field">
                  <label class="form-control-label style-headingkotak">
                  {selectedLanguage === 'id' ?  'Pesan': 'Message'} <span style={{color: "red"}}>*</span>
                  </label>
                  <textarea
                    rows="5"
                    class="form-control size-textfieldCon"
                    name="pesan"
                    placeholder= {selectedLanguage === 'id' ?  'Pesan': 'Message'}
                    onChange={handleChange}
                    value={formData.pesan}
                    required
                    style={{
                      paddingLeft: '40px', // Sesuaikan dengan lebar gambar
                      backgroundImage: `url(${massage})`,
                      backgroundSize: '16px', // Sesuaikan ukuran gambar
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '10px 8px ',
                      marginBottom: '20px',
                      opacity: '80%' // Sesuaikan posisi gambar
                    }}
                  ></textarea>
                </div>
                
                <div className="mt-3">
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </div>

                <button type="submit" class="btn-submit ">
                  Submit
                </button>
                
              </form>
            </div>

            <div className='col'>
            <p className="heading-kotakkanan"> {selectedLanguage === 'id' ?  'Hubungi Kami': 'Contact Us'} </p>
              <p className="isi-kotakkanan">
              PT.Electronic City Indonesia, Tbk. <br/>
                  Jl. Jend. Sudirman Kav. 52-53, SCBD Lot. 22 Jakarta Selatan 12190
              </p>
            
              <div className="grid-kotakkanan">
                  <p className="heading-kotakkanan ">Email</p>

                  <p className="heading-kotakkanan ">Phone</p>
              </div>

              <div className="grid-kotakkanan">
              <a href={`mailto:corporatesecretary@electronic-city.co.id`}><p className='e_corp'>corporatesecretary@electronic-city.co.id</p></a>

                  <p className="isi-kotakkanan">021 - 5151177</p>
              </div>
            </div>
          </div>
          </div>
          </>
        );
        }

      else {
      return (
        <h1>Halaman Kosong</h1>
      );
    }
  }

  return (
    <div>
      <div className='navbar_m'>
        <div className='bantiban'>
          <Navbar isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
          <div className='container hh'>
            <p>
            {selectedLanguage === 'en' ? 'Investor Relations' : 'Hubungan Investor'}
            </p>
          </div>
        </div>
      </div>
      <div className="container cc">
      <div className="ctck">
        <div className="side-menu">       
          <SideMenu />   
        </div>
        <div className="ctck_ktn content">
          <CustomKonten />
        </div>
      </div>
      </div>
      <div className="mn_content">
        <div className='bar_mn'>
            <SideMenu />
          </div>
          <center>
            <hr className='hr_hp'/>
          </center>
          <div className='content_mn'>
            <Customnhp />
            <div>
            </div>
          </div>
      </div>
      <div className='footer_m'>
        <Footer />
      </div>
          </div>
   );
};


export default TenkaPage;


